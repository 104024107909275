import React from 'react'
import config from '../../../data/config'
import {
  FacebookIcon,
  TwitterIcon,
  GooglePlusIcon,
} from 'react-share'


const Footer = () => {

  return (
    <footer className='footer'>
      <div className='container'>
        <div className='content has-text-centered'>
          <p>
            {config.copyright}
            <div className='social-links'>
              <a href='https://www.facebook.com/pg/CocoonKo-311957592762304' target='_blank'><FacebookIcon round size={36} /></a>
              <a href='https://twitter.com/cocoonko1' target='_blank'><TwitterIcon round size={36} /></a>
              {/* <a href='https://goo.gl/forms/RcYQcjgmK7lPCWQ02' target='_blank'><GooglePlusIcon round size={36} /></a> */}
            </div>
          </p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
