module.exports = {
  siteTitle: 'CocoonKo', // Site title.
  siteTitleAlt: 'Proteina de insectios. Entomogagia. Mascotas. Piensos.', // Alternative site title for SEO.
  siteLogo: '/icons/icon-512x512.png', // Logo used for SEO and manifest.
  siteUrl: 'https://cocoonko.com', // Domain of your website without pathPrefix.
  pathPrefix: '/', // Prefixes all links. For cases when deployed to example.github.io/whatever/.
  siteDescription: 'Cuidemos nuestro planeta. Agricultura de Presición, Entomofagia, Economía Circular, Upcycling o Sobreciclaje. Snacks para mascotas, snacks gourmets y pienso.', // Website description used for RSS feeds/meta description tag.
  siteRss: '/rss.xml',
  siteFBAppID: '', // FB Application ID for using app insights
  googleTagManagerID: 'GTM-P6NXJ5B', // GTM tracking ID.
  disqusShortname: 'cocoonko', // Disqus shortname.
  userName: 'vivasonico',
  userTwitter: 'cocoonko1',
  userLocation: 'Barcelona, Spain',
  userDescription: '',
  copyright: 'Copyright © CocoonKo 2019', // Copyright string for the footer of the website and RSS feed.
  themeColor: '#CCA43B', // Used for setting manifest and progress theme colors.
  backgroundColor: '#ffffff', // Used for setting manifest background color.
}
